import Button from "../../components/shared/button";
import { StaticImage } from "gatsby-plugin-image";
import {
    ErrorCard,
    PageNotFound,
    PageNotFoundItem,
    Title,
    Description,
    Number,
} from "./style";
import { navigate } from "gatsby";
import { useEffect } from "react";

const ErrorContent = () => {
    useEffect(() => {
        redirectToHome();
    }, []);

    const redirectToHome = () => {
        setTimeout(function () {
            return navigate("/");
        }, 3000);
    };

    return (
        <ErrorCard>
            <PageNotFound>
                <PageNotFoundItem>
                    <StaticImage
                        src="../../data/images/page-not-found/404error.svg"
                        alt="images_not_found"
                    />
                    {/* <Number>4</Number> */}
                </PageNotFoundItem>
                {/* <PageNotFoundItem>
                    <StaticImage
                        src="../../data/images/page-not-found/2.png"
                        alt="images_not_found"
                    />
                    <Number>0</Number>
                </PageNotFoundItem>
                <PageNotFoundItem>
                    <StaticImage
                        src="../../data/images/page-not-found/3.png"
                        alt="images_not_found"
                    />
                    <Number>4</Number>
                </PageNotFoundItem> */}
            </PageNotFound>
            <Title>Whoops..!!!</Title>
            <Description>We all have some missing pages sometimes.</Description>
            <Description>
                Not to worry! You can head over to our Homepage.
            </Description>
            <Button size="large" path="/" shape="rounded5">
                <i className="icofont-rounded-double-left"></i> GET BACK HOME
                NOW.
            </Button>
        </ErrorCard>
    );
};

export default ErrorContent;
